import { useApplicationContext } from "@/shared/composables/use-application-context";
import { useApiRequestMappers } from "@/shared/composables/use-api-request-mappers";

export const useReportingApi = () => {
  const { logger, httpClient } = useApplicationContext();
  const { keysToSnakeCase } = useApiRequestMappers();

  const getTotalPaxReport = async (params) => {
    return generateReport("/reporting/total_pax", {
      timeout: 120000,
      year: params.financialYear,
    });
  };

  const getSalesReport = async (params) => {
    return generateReport("/reporting/sales", params);
  };

  const getSalesReportByDepartureDate = async (params) => {
    return generateReport("/reporting/sales-by-departure-date", params);
  };

  const getReservationTransactionsReport = async (params) => {
    return generateReport("/reporting/transactions", params);
  };

  const generateReport = async (url, params) => {
    const requestData = keysToSnakeCase(params);

    try {
      const response = await httpClient.post(url, requestData, {
        timeout: 120000,
        responseType: "arraybuffer",
      });
      return response;
    } catch (error) {
      logger.error("Failed to generate report", error);
      throw error;
    }
  };

  return {
    getTotalPaxReport,
    getSalesReport,
    getSalesReportByDepartureDate,
    getReservationTransactionsReport,
  };
};
